<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-10">
      <div class="card full-height bg-ligth">
        <div class="card-header">

          <h3 class="mb-0">
            {{ saida_id != "" ? "Editar" : "Criar" }} saida de produtos
          </h3>

        </div>
        <div class="card-body">
          <div class="form">


            <div class="form-group row">

              <div class="col-md-4">
                <label class="col-md-12">Lote :</label>
                <b-input-group class="mb-2 mr-sm-2 mb-sm-0 bg-secondary">
                  <input required type="text" class="form-control" v-model="nome_pesquisa" @click="pesquisar()" />
                  <b-button v-b-modal.SelecionarLote class="btn btn-indo">
                    <i class="fa fa-search search-icon m-0 p-0"></i>
                  </b-button>
                </b-input-group>
                <b-modal size="lg" id="SelecionarLote" hide-footer hide-header title="SelecionarLote" ref="modal_lote">
                  <input required type="text" class="form-control" v-model="nome_pesquisa" />
                  <b-table :fields="['lote', 'produto', 'validade', 'fracao', 'acoes']" :items="lista_estoque_produtos"
                    :per-page="perPage" :current-page="currentPage" id="atendimento-table"
                    class="table table-hover table-responsive" show-empty empty-text="Nenhum registro encontrado!">
                    <template #cell(acoes)="{ item }">
                      <button @click="selecionarEstoque(item)" class="btn btn-light mx-1" v-b-tooltip.hover>
                        Selecionar
                      </button>
                    </template>
                  </b-table>
                  <b-pagination v-model="currentPage" :total-rows="lista_estoque_produtos.length" :per-page="perPage"
                    aria-controls="atendimento-table">
                  </b-pagination>
                </b-modal>
              </div>

              <div class="col-md-8">
                <label class="col-md-12">Producto : <span v-b-tooltip.hover
                    title="Aquí lista los productos de la siguiente manera: nombre - descripción - laboratorio - presentación"><svg
                      xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor"
                      class="bi bi-question-circle-fill" viewBox="0 0 16 16">
                      <path
                        d="M16 8A8 8 
                        0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
                    </svg></span></label>
                <treeselect v-model="produto_id" :multiple="false" :options="lista_produtos" />
              </div>

            </div>

            <div class="form-group row">

              <div class="col-md-6">
                <label class="col-md-12">Selecione el estoque :*</label>
                <select required type="text" class="form-control" v-model="form.estoque_produto_id"
                  placeholder="Digite Tipo de Produto">
                  <option v-for="lista in lista_produtos_estoque" :key="lista.id" :value="lista.id">
                    Lote: {{ lista.lote }} V{{ lista.validade }} - Cantidad:
                    {{ lista.estoque_quantidade }} - Fracion: {{ lista.estoque_fracao }} 
                  </option>
                </select>
              </div>

              <div class="col-md-6">
                <label class="col-md-12">Salida por :*</label>
                <select required type="text" class="form-control" v-model="form.tipo_saida_id" placeholder="seleccione">
                  <!-- <option value="1">Paciente</option> -->
                  <option value="2">Vencimiento</option>
                  <option value="3">Danificado</option>
                  <option value="4">Donación</option>
                  <option value="5">Estoque Retroativo (sigue en sistema, pero ya no hay en físico)</option>
                  <option value="6">Salida unica para uso local(Productos como algodon, alcool...)</option>
                </select>
              </div>


            </div>
            <div class="form-group row">
              <div class="col-md-5">
                <label class="col-md-12"> Cantidad en Fracion:*</label>
               
                <input v-if="fracao_produto <= 0" disabled type="number" class="form-control" v-model="quantidade_fracao"
                  placeholder="Digite o nome..." />

                  <input v-else="fracao_produto <= 0" required type="number" class="form-control" v-model="quantidade_fracao"
                  placeholder="Digite o nome..." />
              </div>
              <div class="col-md-2">
                <label class="col-md-12">Fracion</label>
                <input required type="number" class="form-control" disabled 
                  placeholder="" v-model="fracao_produto" />
              </div>

              <div class="col-md-5">
                <label class="col-md-12"> Cantidad unitaria:*</label>
                <input required type="number" class="form-control" disabled v-model="quantidade"
                  placeholder="Digite o nome..." />
              </div>
            </div>
            <div class="form-group row" v-if="form.tipo_saida_id == 1">
              <div class="col-md-4">

                <label class="col-md-12">Seleccionar Paciente:*</label>

                <!-- <select
                  required
                  type="text"
                  class="form-control"
                  v-model="form.paciente_id"
                  placeholder="Digite Tipo de Produto"
                >
                  <option
                    v-for="lista in lista_usuarios"
                    :key="lista.id"
                    :value="lista.id"
                  >
                    {{ lista.nome }}
                  </option>
                </select> -->

                <treeselect v-model="form.paciente_id" :multiple="false" :options="lista_usuarios" />
              </div>
              <div class="col-md-4">
                <label class="col-md-12">Seleccionar atendimiento:
                  <span v-b-tooltip.hover title="Aquí hay una lista de atendimientos que estan ABIERTOS del paciente"><svg
                      xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-question-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z" />
                    </svg></span></label>

                <!-- <select
                  required
                  type="text"
                  class="form-control"
                  v-model="form.paciente_id"
                  placeholder="Digite Tipo de Produto"
                >
                  <option
                    v-for="lista in lista_usuarios"
                    :key="lista.id"
                    :value="lista.id"
                  >
                    {{ lista.nome }}
                  </option>
                </select> -->

                <treeselect v-model="form.paciente_id" :multiple="false" :options="lista_usuarios" />
              </div>
              <div class="col-md-4">
                <label class="col-md-12">Seleccionar ocorrencia:*</label>

                <!-- <select
                  required
                  type="text"
                  class="form-control"
                  v-model="form.paciente_id"
                  placeholder="Digite Tipo de Produto"
                >
                  <option
                    v-for="lista in lista_usuarios"
                    :key="lista.id"
                    :value="lista.id"
                  >
                    {{ lista.nome }}
                  </option>
                </select> -->

                <treeselect v-model="form.paciente_id" :multiple="false" :options="lista_usuarios" />
              </div>
            </div>


            <div class="form-group row">
              <div class="col-md-12">
                <label class="col-md-12"> Descripcion:</label>
                <input required type="text" class="form-control" v-model="form.descricao"
                  placeholder="Describe aquí el motivo de la salida..." />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button @click.prevent="confirm(saida_id != '' ? 'edita' : 'cria')" class="btn btn-primary"
                  :disabled="verif">
                  Salvar
                  <b-spinner v-show="verif" small variant="dark" type="grow" label="Spinning"></b-spinner>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import ApiService from "@/core/services/api.service";

export default {
  mixins: [fireAlert],
  data() {
    return {
      selectedProduto: null,
      form: {
        estoque_produto_id: null,
        quantidade: null, // quantidade do estoque em fracao
        paciente_id: null,
        receita_id: null,
        descricao: null,
        tipo_saida_id: null,
      },
      quantidade_fracao:"",// quantidade do estoque em fracao
      quantidade:"",// quantidade inteira
      produto_id: null,
      saida_id: "",
      verif: false,
      lista_campos: {
        id: "",
      },
      currentPage: 10,
      perPage: 10,
      nome_pesquisa: "",
      fracao_produto: 0,
    };
  },
  created() {
    this.listar_dados();
  },

  computed: {
    lista_produtos() {
      return this.$store.state.produto.lista_produtos;
    },
    lista_estoque_produtos() {
      return this.$store.state.produto.lista_estoque_produtos;
    },
    lista_produtos_estoque() {
      return this.$store.state.produto.lista_produtos_estoque;
    },
    lista_usuarios() {
      return this.$store.state.configUsuarios.lista_usuarios;
    },
    mensagem_alert() {
      return this.$store.state.produto.mensagem_alert;
    },
    lista_filials() {
      return this.$store.state.configEmpresa.lista_filials;
    },
    lista_saida_produtos() {
      return this.$store.state.produto.lista_saida_produtos;
    },

  },

  watch: {
    produto_id() {
      this.descobrir_fracao(this.produto_id)
      this.listar_estoque();
    },
    nome_pesquisa() {
      if (this.nome_pesquisa.length >= 3) {
        var p = { texto: this.nome_pesquisa, tipo: "local" };
        this.$store.dispatch("produto/pesquisar_estoque_por_lotes", p);
      }
      if (this.nome_pesquisa.length == 0) {
        this.form.estoque_produto_id = null;
        this.produto_id = null;
      }
    },
    quantidade_fracao(){
      this.quantidade =(this.quantidade_fracao/this.fracao_produto).toFixed(2)
      this.form.quantidade = this.quantidade_fracao
    }
  },
  methods: {
    descobrir_fracao(produto_id) {
      const lista = this.lista_produtos
      console.log(lista.length)
      for (let i = 0; i < lista.length; i++) {
        if (lista[i].id == produto_id) {
          this.fracao_produto = lista[i].fracao
        }

      }
    },
    async listar_dados() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("produto/listar_produto_ativo");
      const { saida_produto_id } = this.$route.params;
      if (saida_produto_id) {
        await this.$store.dispatch(
          "produto/get_saida_produto",
          saida_produto_id
        );
        await this.preenxerCampos();
      }
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },
    selecionarEstoque(item) {
      this.form.estoque_produto_id = item.id;
      this.produto_id = item.produto_id;
      this.descobrir_fracao(this.produto_id)
      this.$refs["modal_lote"].hide();
    },
    pesquisar() {
      this.$store.dispatch(
        "produto/pesquisar_estoque_por_lotes",
        this.nome_pesquisa
      );
    },
    async listar_estoque() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch(
        "produto/listar_estoques_disponiveis",
        this.produto_id
      );
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
    },

    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` uma saida no sistema.`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      await this.$store.dispatch("produto/create_saida_produto", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "saidaProduto",
      });
    },
    async update() {
      this.verif = true;
      var dados = {
        form: this.form,
        id: this.saida_id,
      };
      this.form.id = this.saida_id;
      console.log(this.form);
      await this.$store.dispatch("produto/update_saida_produto", dados);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "saidaProduto",
      });
    },

    async preenxerCampos() {
      this.saida_id = this.lista_saida_produtos.id;
      this.form.estoque_produto_id =
        this.lista_saida_produtos.estoque_produto_id;
      this.produto_id = this.lista_saida_produtos.produto_id;
      this.form.quantidade = this.lista_saida_produtos.quantidade;
      this.form.receita_id = this.lista_saida_produtos.receita_id;
      this.form.paciente_id = this.lista_saida_produtos.paciente_id;
      this.form.descricao = this.lista_saida_produtos.descricao;
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}

.custom-tooltip .tooltip-inner {
  max-height: 100%;
  /* Defina a largura máxima do tooltip */
  /* Outros estilos personalizados conforme necessário */
}
</style>
